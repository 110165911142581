import bgAudioFile from '../assets/ObservingTheStar-reduced.mp3'
export default class AudioController{
    private bgAudio: any
    constructor(){
        this.init()
    }
    init(){
        this.bgAudio = new Audio()
        this.bgAudio.src = bgAudioFile
        this.bgAudio.load()
        this.bgAudio.loop = true
    }
    play(){
        this.bgAudio.play()
    }
    pause(){
        this.bgAudio.pause()
    }
}