import './styles/gallery.css'
import EarthMarker from "./EarthMarker";
import EarthMarkerController from './EarthMarkerController';
import { isMobile } from './utils';

export default class Gallery {
    private markerController: EarthMarkerController
    private galleryScreen: HTMLElement
    private arrowLeft: HTMLElement
    private arrowRight: HTMLElement
    

    constructor() {
        this.init()
    }

    init() {
        this.galleryScreen = document.querySelector('#gallery-screen')
        this.arrowLeft = document.querySelector('#gallery-screen .arrow-left')
        this.arrowRight = document.querySelector('#gallery-screen .arrow-right')

        // this.arrowLeft.addEventListener('click', () => {
        //     this.markerController.galleryMoveLeft()
        // })
        // this.arrowRight.addEventListener('click', () => {
        //     this.markerController.galleryMoveRight()
        // })
        this.hideArrows()
    }

    

    setMarkerController(markerController: EarthMarkerController){
        this.markerController = markerController
    }

    openGallery(){
        this.galleryScreen.classList.add('show')
    }

    closeGallery() {
        this.galleryScreen.classList.remove('show')
        // if(!isMobile()){
        //     this.arrowLeft.classList.remove('hide')
        //     this.arrowRight.classList.remove('hide')
        // }
        
        document.querySelector('#gallery-screen .cities').classList.remove('hide')
    }

  
    setMarkers(markers: EarthMarker[]) {
        const citiesContainer = document.querySelector('#gallery-screen .cities') as HTMLElement
        let citiesContent = ''
        markers.forEach((marker, index) => {
            if(marker.name)
                citiesContent += `<p>${marker.name}</p>`
            else 
                citiesContent += `<p>?</p>`
        })
        citiesContainer.innerHTML = citiesContent
    }

    hideArrows() {
        this.arrowLeft.classList.add('hide')
        this.arrowRight.classList.add('hide')
    }
}
