import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

import earthModel from "../assets/glb/earth.glb"
import ParticleSystemController from './ParticleSystemController';
import EarthMarker from './EarthMarker';
import gsap from 'gsap';
import Chat from "./Chat";
import Gallery from "./Gallery";
import EarthMarkerController from './EarthMarkerController';

export default class Earth {
    private scene: BABYLON.Scene
    private camera: BABYLON.FreeCamera
    public root: any
    public mesh: BABYLON.AbstractMesh;
    private isDragging: boolean = false;
    private currentRotationSpeed: number = 0.001;
    private particleController: ParticleSystemController;
    public rootTransformNode: BABYLON.TransformNode
    public isEarthRotation: boolean = false
    public activeMarker: number | null = null
    private markers: EarthMarker[] = []
    private coordinates: {lat: number, lon: number, visible: boolean, toFrame: boolean, name: string}[] = [

        {lat: 35.3461118, lon: 139.5155926, visible: true, toFrame: false, name: '1370 Okamoto, Kamakura, Kanagawa 247-0072, Japan'},
        {lat: 35.3252337, lon: 139.5500598, visible: false, toFrame: false, name: '1-chōme-16-3 Ōgigayatsu, Kamakura, Kanagawa 248-0011, Japan' },
        {lat: 35.3368918, lon: 139.5458293, visible: false, toFrame: false, name: '8GPW+Q8 Kamakura, Kanagawa Prefecture, Japan'},

        // {lat: 35.3090292, lon: 139.5163805, visible: false, toFrame: false, name: 'Japan, 〒248-0025 Kanagawa, Kamakura, Shichirigahamahigashi, 4-chōme−1, みどりのプロムナード'},
        // {lat: 35.317548, lon: 139.5328676, visible: false, toFrame: false, name: '4-chōme-4-2 Hase, Kamakura, Kanagawa 248-0016, Japan'},
        // {lat: 35.3368918, lon: 139.5458293, visible: false, toFrame: false, name: '8GPW+Q8 Kamakura, Kanagawa Prefecture, Japan'},
        // {lat: 35.3062576, lon: 139.5552867, visible: false, toFrame: false, name: '6-chōme-9-23 Zaimokuza, Kamakura, Kanagawa 248-0013, Japan'},
        // {lat: 35.3082679, lon: 139.5019047, visible: false, toFrame: false, name: '1-chōme-2-1-2 Koshigoe, Kamakura, Kanagawa 248-0033, Japan'},
        // {lat: 35.367856, lon: 139.5225537, visible: false, toFrame: false, name: '1459-38 Tayachō, Sakae Ward, Yokohama, Kanagawa 244-0844, Japan'}

    ]
    public chat: any
    public gallery: any
    private markerController: EarthMarkerController
    private pipeline: BABYLON.DefaultRenderingPipeline
    private isStart: boolean = false

    constructor(scene: BABYLON.Scene, camera: BABYLON.FreeCamera, pipeline: BABYLON.DefaultRenderingPipeline) {
        this.pipeline = pipeline
        this.scene = scene
        this.camera = camera
        this.init()
    }

    init(){
        this.chat = new Chat()
        this.gallery = new Gallery()
        this.markerController = new EarthMarkerController(this, this.scene, this.camera, this.gallery, this.chat)
        this.gallery.setMarkerController(this.markerController)
        this.chat.setEarthMarkerController(this.markerController)
    }



    public bloomEnabled(value: boolean){
        this.pipeline.bloomEnabled = value
    }

    async load(): Promise<Earth> {
        const earth = await BABYLON.SceneLoader.ImportMeshAsync(
            "",
            "",
            earthModel,
            this.scene,
            undefined,
            ".glb"
        );
        this.rootTransformNode = new BABYLON.TransformNode("root_transform_fix");
        this.rootTransformNode.rotation = new BABYLON.Vector3(0, 0, 0);
        this.rootTransformNode.scaling = new BABYLON.Vector3(1, 1, 1);
        this.root = earth;
        this.mesh = earth.meshes[0];
        this.mesh.position = new BABYLON.Vector3(0, 0, 0);
        const rotationVector = new BABYLON.Vector3(0, 0, 0);
        const quaternionRotation = BABYLON.Quaternion.FromEulerVector(rotationVector);
        this.mesh.rotationQuaternion = quaternionRotation;
        this.rootTransformNode.addChild(this.mesh)
        this.rootTransformNode.scaling.scaleInPlace(0);

        this.setupPointerEvents();
        this.animateRotation();
        this.createParticles()

        await this.loadMarkers()

        this.gallery.setMarkers(this.markers)
        this.markerController.setMarkers(this.markers)

        return this;
    }

    public async loadMarkers(): Promise<void> {
        const markerPromises = this.coordinates.map(async (coord, id) => {
            const marker = new EarthMarker(
                this.scene,
                this.mesh as BABYLON.Mesh,
                this,
                coord.lat,
                coord.lon,
                coord.toFrame,
                id,
                coord.name,
                this.chat,
                this.markerController
            );
            await marker.addMarker(coord.visible);
            this.markers[id] = marker;
        });

        await Promise.all(markerPromises);
    }

    firstAnimationToLocation(id: number){
        // this.isStart = true
        const markerMesh = this.markers[id].getMarkerMesh()
        let cameraDirection = this.camera.position.subtract(this.mesh.position).normalize();
        let pointDirection = markerMesh.getAbsolutePosition().subtract(this.mesh.position).normalize();
        let cameraDirXZ = new BABYLON.Vector3(cameraDirection.x, 0, cameraDirection.z).normalize();
        let pointDirXZ = new BABYLON.Vector3(pointDirection.x, 0, pointDirection.z).normalize();
        let angleY = Math.acos(BABYLON.Vector3.Dot(cameraDirXZ, pointDirXZ));
        let signY = BABYLON.Vector3.Cross(pointDirXZ, cameraDirXZ).y >= 0 ? 1 : -1;
        let quaternionY = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.Y, signY * angleY);
        let cameraDirYZ = new BABYLON.Vector3(0, cameraDirection.y, cameraDirection.z).normalize();
        let pointDirYZ = new BABYLON.Vector3(0, pointDirection.y, pointDirection.z).normalize();
        let angleX = Math.acos(BABYLON.Vector3.Dot(cameraDirYZ, pointDirYZ));
        let signX = BABYLON.Vector3.Cross(cameraDirYZ, pointDirYZ).x >= 0 ? 1 : -1;
        let quaternionX = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.X, signX * angleX);
        let extraRotationX = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.X, Math.PI);
        let combinedQuaternion = extraRotationX.multiply(quaternionX).multiply(quaternionY).multiply(this.mesh.rotationQuaternion);

        let animation = new BABYLON.Animation("rotationQuaternionAnimation", "rotationQuaternion", 30,
            BABYLON.Animation.ANIMATIONTYPE_QUATERNION, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
        let startQuaternion = this.mesh.rotationQuaternion.clone();
        let endQuaternion = combinedQuaternion
        let keys = [
            { frame: 0, value: startQuaternion },
            { frame: 100, value: endQuaternion }
        ];
        animation.setKeys(keys);
        this.mesh.animations.push(animation);
        this.scene.beginAnimation(this.mesh, 0, 100, false, 1, () => {

            this.markers[id].tapAction()
        });
    }



    createParticles(){
        this.particleController = new ParticleSystemController(this.scene, this.mesh);
    }

    setPosition(position: BABYLON.Vector3): void {
        this.mesh.position = position;
    }

    setRotation(quaternion: BABYLON.Quaternion): void {
        this.mesh.rotationQuaternion = quaternion;
    }

    private animateRotation(): void {
        this.scene.onBeforeRenderObservable.add(() => {
            if (!this.isDragging && this.isEarthRotation) {
                const currentEuler = this.mesh.rotationQuaternion.toEulerAngles();

                const targetX = 0;
                let deltaX = targetX - currentEuler.x;
                if (deltaX > Math.PI) deltaX -= 2 * Math.PI;
                if (deltaX < -Math.PI) deltaX += 2 * Math.PI;
                currentEuler.x += deltaX * 0.001;

                const targetZ = Math.PI;
                let deltaZ = targetZ - currentEuler.z;
                if (deltaZ > Math.PI) deltaZ -= 2 * Math.PI;
                if (deltaZ < -Math.PI) deltaZ += 2 * Math.PI;
                currentEuler.z += deltaZ * 0.001;

                const targetQuaternion = BABYLON.Quaternion.FromEulerAngles(currentEuler.x, currentEuler.y, currentEuler.z);

                const deltaRotation = BABYLON.Quaternion.FromEulerAngles(0, -this.currentRotationSpeed, 0);
                this.mesh.rotationQuaternion = deltaRotation.multiply(targetQuaternion);
            }
        });
    }
    public show(){

        const animationX = new BABYLON.Animation(
            "scaleUpX",
            "scaling.x",
            30,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        const animationY = new BABYLON.Animation(
            "scaleUpY",
            "scaling.y",
            30,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        const animationZ = new BABYLON.Animation(
            "scaleUpZ",
            "scaling.z",
            30,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        const keyFrames = [];
        keyFrames.push({
            frame: 0,
            value: 1
        });

        keyFrames.push({
            frame: 50,
            value: 2
        });

        animationX.setKeys(keyFrames);
        animationY.setKeys(keyFrames);
        animationZ.setKeys(keyFrames);

        // add animations to object
        this.rootTransformNode.animations = [];
        this.rootTransformNode.animations.push(animationX);
        this.rootTransformNode.animations.push(animationY);
        this.rootTransformNode.animations.push(animationZ);

        // play animation
        this.scene.beginAnimation(this.rootTransformNode, 0, 50, false, 1, () => {
            this.firstAnimationToLocation(0)
        });

    }

    private setupPointerEvents(): void {
        let startPosition: BABYLON.Vector3 | null = null;
        let startRotation: BABYLON.Quaternion | null = null;

        this.scene.onPointerObservable.add((pointerInfo) => {
            switch (pointerInfo.type) {
                case BABYLON.PointerEventTypes.POINTERDOWN:
                    if(!this.isEarthRotation) return
                    this.isDragging = true;
                    this.scene.stopAnimation(this.mesh);

                    // set start position and start orientation
                    startPosition = this.getArcballVector(pointerInfo.event.clientX, pointerInfo.event.clientY);
                    startRotation = this.mesh.rotationQuaternion.clone();
                    break;

                case BABYLON.PointerEventTypes.POINTERMOVE:
                    if(!this.isEarthRotation) return
                    if (this.isDragging && startPosition && startRotation) {
                        // get current cursor position
                        const currentPosition = this.getArcballVector(pointerInfo.event.clientX, pointerInfo.event.clientY);
                        // calculate angle rotation
                        const angle = -Math.acos(Math.min(1, BABYLON.Vector3.Dot(startPosition, currentPosition))); // Инвертируем угол
                        // calcuate axis rotation
                        const axis = BABYLON.Vector3.Cross(startPosition, currentPosition).normalize();
                        // create rotation
                        const quaternion = BABYLON.Quaternion.RotationAxis(axis, angle);
                        // apply rotation
                        this.mesh.rotationQuaternion = quaternion.multiply(startRotation);
                    }
                    break;

                case BABYLON.PointerEventTypes.POINTERUP:
                    this.isDragging = false;
                    break;
            }
        });
    }

    private getArcballVector(clientX: number, clientY: number): BABYLON.Vector3 {
        const canvas = this.scene.getEngine().getRenderingCanvas();
        if (!canvas) {
            return BABYLON.Vector3.Zero();
        }

        let x = (2 * clientX - canvas.width) / canvas.width;
        let y = (canvas.height - 2 * clientY) / canvas.height;
        let z = 0;

        const d = Math.sqrt(x * x + y * y);
        if (d > 1) {
            x /= d;
            y /= d;
        } else {
            z = Math.sqrt(1 - d * d);
        }

        return new BABYLON.Vector3(x, y, z);
    }

    public disposeParticles(): void {
        this.particleController.dispose();
    }
}
