import * as BABYLON from 'babylonjs';

export default class ParticleSystemController {
    private particleSystem: BABYLON.ParticleSystem;

    constructor(private scene: BABYLON.Scene, private targetMesh: BABYLON.AbstractMesh) {
        this.createParticleSystem();
    }

    private createParticleSystem(): void {
        this.particleSystem = new BABYLON.ParticleSystem("particles", 10000, this.scene);
        this.particleSystem.emitRate = 100;
        this.particleSystem.particleTexture = new BABYLON.Texture("./public/textures/circle.png", this.scene);

        var boxEmitter = new BABYLON.BoxParticleEmitter();
        boxEmitter.minEmitBox = new BABYLON.Vector3(-5, -5, 5);
        boxEmitter.maxEmitBox = new BABYLON.Vector3(5, 5, 3);

        this.particleSystem.emitter = new BABYLON.Vector3(this.targetMesh.position.x, this.targetMesh.position.y, this.targetMesh.position.z -3)
        this.particleSystem.particleEmitterType = boxEmitter;
        this.particleSystem.direction1 = new BABYLON.Vector3(-.1, .1, 5);
        this.particleSystem.direction2 = new BABYLON.Vector3(.1, .1, 0);
        
        this.particleSystem.minSize = 0.01;
        this.particleSystem.maxSize = 0.03;

        this.particleSystem.minLifeTime = 0.3;
        this.particleSystem.maxLifeTime = 10;
        this.particleSystem.minInitialRotation = -2 * Math.PI;
        this.particleSystem.maxInitialRotation = 2 * Math.PI;
   
        this.particleSystem.minEmitPower = 0.01;
        this.particleSystem.maxEmitPower = 0.1;
        
        this.particleSystem.updateSpeed = 0.05;
        this.particleSystem.start();
    }

    public dispose(): void {
        this.particleSystem.stop();
        this.particleSystem.dispose();
    }
}