import { Loader } from "@googlemaps/js-api-loader";

const APIKEY = 'AIzaSyBj4JQwYFz2Tlv0l4hsLSgVAEEsuh7K7a0'
export default class Panorama {
    // Define properties
    private panorama: google.maps.StreetViewPanorama | undefined;
    private canvas: HTMLCanvasElement;
    public image: string | undefined;
    public zoom: number;

    // Constructor to initialize the object
    constructor(zoom: number) {
        this.zoom = zoom;
        this.canvas = document.createElement('canvas');
    }

    public async initialize(position: google.maps.LatLngLiteral, callback: () => void): Promise<void> {
        const loader = new Loader({
            // apiKey: "AIzaSyDHD1LGrGTxLFqi545esvdX57lBau0ua-w",
            apiKey: APIKEY,
            version: "weekly",
        });

        console.log("Loading Google Maps API...");

        await loader.load();
        
        console.log("Google Maps API loaded!");
        this.panorama = new google.maps.StreetViewPanorama(
            document.createElement("div") as HTMLElement,
            {
                position,
                pov: {
                    heading: 34,
                    pitch: 10,
                },
            }
        );

        this.panorama.addListener("pano_changed", async () => {
            
            await this.handlePositionChange(position);
            callback()
        });
    }

    public getImage() {
        return this.image;
    }

    public setPosition(location: google.maps.LatLngLiteral, zoom: number): void {
        this.panorama.setPosition(location);
        this.zoom = zoom;
    }

    private async handlePositionChange(position: google.maps.LatLngLiteral) {
        const panoId = this.panorama.getPano();
        if (panoId.length < 22) {
            console.error("Panorama does not have a correct id");
            return;
        }

        const tileCoordinates = this.generateTileCoordinates(this.zoom);

        const exampleTile = await this.getTile(panoId, 0, 0, this.zoom);
        if (!exampleTile) {
            console.error("Tile was not found");
            return;
        }

        this.setCanvasSize(Math.pow(2, this.zoom), Math.pow(2, this.zoom) / 2, exampleTile.width, exampleTile.height);

        const ctx = this.canvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }

        for (const tile of tileCoordinates) {
            await this.getTile(panoId, tile.x, tile.y, this.zoom);
        }
        
        this.image = await this.convertToImage();
    }

    // public async getPlaceName(lat: number, lng: number): Promise<string | null> {
    //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIKEY}&language=en`;

    //     try {
    //         const response = await fetch(url);
    //         const data = await response.json();

    //         if (data.status === 'OK' && data.results.length > 0) {
    //             const placeName = data.results[0].formatted_address;
    //             console.log(data.results[0])
    //             return placeName;
    //         } else {
    //             console.error('No results found or invalid response from Geocoding API');
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error('Error fetching place name:', error);
    //         return null;
    //     }
    // }

    private async getTile(panoId: string, x: number, y: number, zoom: number): Promise<{ width: number; height: number; } | undefined> {
        try {
            const url = `https://streetviewpixels-pa.googleapis.com/v1/tile?cb_client=apiv3&panoid=${panoId}&output=tile&x=${x}&y=${y}&zoom=${zoom}&nbt=1&fover=2`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            const img = new Image();
            img.src = imageUrl;

            await new Promise<void>((resolve) => {
                img.onload = () => {
                    resolve();
                    URL.revokeObjectURL(imageUrl);
                };
            });

            const ctx = this.canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Failed to get canvas context');
            }
            ctx.drawImage(img, x * img.width, y * img.height);

            return img;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            return undefined;
        }
    }

    private generateTileCoordinates(zoom: number): Array<{ x: number, y: number }> {
        const numTilesX = Math.pow(2, zoom);
        const numTilesY = numTilesX / 2;

        return Array.from({ length: numTilesX }, (_, x) =>
            Array.from({ length: numTilesY }, (_, y) => ({ x, y }))
        ).flat();
    }

    private setCanvasSize(width: number, height: number, tileWidth: number, tileHeight: number): void {
        this.canvas.width = width * tileWidth;
        this.canvas.height = height * tileHeight;
    }

    private async convertToImage() {
        return new Promise<string>((resolve) => {
            const image = this.canvas.toDataURL('image/png');
            resolve(image);
        });
    }
}
