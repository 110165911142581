import * as BABYLON from 'babylonjs';
import Earth from './Earth';
import Panorama from './Panorama';
import Scene from './Scene';
import gsap from 'gsap';
import EarthMarkerController from './EarthMarkerController';
import { isMobile } from './utils';

export default class EarthMarker {
    private scene: BABYLON.Scene;
    public earthMesh: BABYLON.Mesh;
    private earthRadius: number = 1.13;
    private earth: Earth
    private latitude: number
    private longitude: number
    public toFrame: boolean
    private markerSize: number = 0.05
    public markerId: number
    public name: string
    private marker: BABYLON.Mesh
    private markerMaterial: BABYLON.StandardMaterial
    public chat: any
    public imageUrl: string
    private markerTexture: BABYLON.Texture
    private markerController: EarthMarkerController

    constructor(
        scene: BABYLON.Scene,
        earthMesh: BABYLON.Mesh,
        earth: Earth,
        latitude: number,
        longitude: number,
        toFrame: boolean,
        markerId: number,
        name: string,
        chat: any,
        markerController: EarthMarkerController
    ) {
        this.markerController = markerController
        this.scene = scene;
        this.earthMesh = earthMesh;
        this.earth = earth;
        this.latitude = latitude;
        this.longitude = longitude;
        this.toFrame = toFrame;
        this.markerId = markerId;
        this.name = name;
        this.chat = chat;
    }

    public getMarkerMesh(){
        return this.marker
    }

    public async addMarker(visible: boolean): Promise<void> {
        const panorama = new Panorama(3)
        // const placeName = await panorama.getPlaceName(this.latitude, this.longitude)
        // this.name = placeName
        const imageUrl = await new Promise<string>((resolve) => {
            panorama.initialize({lat: this.latitude, lng: this.longitude}, () => {
                const url = panorama.getImage();
                resolve(url);
            });
        });
        this.imageUrl = imageUrl
        this.markerTexture = new BABYLON.Texture(imageUrl, this.scene);
        this.markerTexture.vScale = -1;
        // Convert latitude and longitude to radians
        const lat = BABYLON.Tools.ToRadians(this.latitude);
        const lon = BABYLON.Tools.ToRadians(this.longitude);

        const position = this.updatePosition(lat, lon, this.earthRadius)

        // Create a 3D sphere for the marker
        this.marker = BABYLON.MeshBuilder.CreateSphere("marker", { diameter: this.markerSize }, this.scene);
        this.marker.name = 'marker' + this.markerId
        this.marker.visibility = Number(visible)

        // // Set up the material for a glowing effect
        this.markerMaterial = new BABYLON.StandardMaterial("markerMaterial", this.scene);
        this.markerMaterial.emissiveColor = this.toFrame ? new BABYLON.Color3(0,1,0) : new BABYLON.Color3(1, 1, 0);
        this.markerMaterial.backFaceCulling = false
        this.marker.material = this.markerMaterial;
        // Set the position of the sphere
        this.marker.position = position;

        // Attach the marker to the Earth model so that it follows its transformations
        this.marker.parent = this.earthMesh;

        this.marker.actionManager = new BABYLON.ActionManager(this.scene);

        // Cursor hover
        this.marker.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, () => {
                const mode = this.markerController.getMode()
                if(mode === 'gallery' || mode === 'full') return
                const animatable = this.scene.beginAnimation(this.earth.mesh, 0, 50, false);
                animatable.pause();
                this.earth.isEarthRotation = false;
                this.marker.scaling = new BABYLON.Vector3(5,5,5)
                const position = this.updatePosition(lat, lon, this.earthRadius + 0.1)
                this.marker.position = position;
                if (imageUrl) {
                    // texture.uScale = -1;
                    this.markerMaterial.diffuseTexture = this.markerTexture
                    this.markerMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
                } else {
                    // Set up the material for a glowing effect if no texture is provided
                    this.markerMaterial.emissiveColor = this.toFrame ? new BABYLON.Color3(0,1,0) : new BABYLON.Color3(1, 1, 0);
                }

            })
        );

        // Cursor out from marker
        this.marker.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, () => {
                const mode = this.markerController.getMode()
                if(mode === 'gallery' || mode === 'full') return
                // this.earth.isEarthRotation = true;
                // const animatable = this.scene.beginAnimation(this.earth.mesh, 0, 50, false);
                // animatable.restart();
                this.marker.scaling = new BABYLON.Vector3(1, 1, 1 )
                this.markerMaterial.emissiveColor = this.toFrame ? new BABYLON.Color3(0,1,0) : new BABYLON.Color3(1, 1, 0);
                const position = this.updatePosition(lat, lon, this.earthRadius)
                this.marker.position = position;
                this.markerMaterial.diffuseTexture = null
            })
        );

        this.marker.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
                this.tapAction()
                
            })
        );
    }

    public tapAction(){
        const mode = this.markerController.getMode()
        if(mode === 'full') return
       
        if(isMobile()){
            const animatable = this.scene.beginAnimation(this.earth.mesh, 0, 50, false);
            animatable.pause();
            this.earth.isEarthRotation = false;
        }
        if(mode === 'globe'){
            this.marker.scaling = new BABYLON.Vector3(5,5,5)
            this.markerMaterial.diffuseTexture = this.markerTexture
            this.markerMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
            this.markerController.setActiveMarker(this.markerId)
            this.markerController.selectMarkerGlobal('gallery');
        }
        if(mode === 'gallery'){
            this.markerController.selectMarkerGallery('full');
        }
    }

    public enabledTexture(value: boolean){
        if(value){
            this.markerMaterial.diffuseTexture = this.markerTexture
            this.markerMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        } else {
            this.markerMaterial.emissiveColor = this.toFrame ? new BABYLON.Color3(0,1,0) : new BABYLON.Color3(1, 1, 0);
        }
    }

    public getMarkerID(){
        return this.markerId
    }

    updatePosition(lat: number, lon: number, radius: number){
        // Calculate the coordinates of the point on the sphere considering the Earth's radius
        const x = radius * Math.cos(lat) * Math.sin(lon);
        const y = radius * Math.sin(lat);
        const z = radius * Math.cos(lat) * Math.cos(lon);

        // Create a vector position relative to the center of the Earth
        return new BABYLON.Vector3(x, y, z);
    }

    public resetMarker(){
        // this.earth.isEarthRotation = true;
        // const animatable = this.scene.beginAnimation(this.earth.mesh, 0, 50, false);
        // animatable.restart();
        
        this.markerMaterial.emissiveColor = this.toFrame ? new BABYLON.Color3(0,1,0) : new BABYLON.Color3(1, 1, 0);
        this.markerMaterial.diffuseTexture = null
        this.marker.parent = this.earthMesh;
        const lat = BABYLON.Tools.ToRadians(this.latitude);
        const lon = BABYLON.Tools.ToRadians(this.longitude);
        this.marker.position = this.updatePosition(lat, lon, this.earthRadius)
        if(this.markerId === 0) this.marker.visibility = 1
    }

    
    public createFrame(){
        return `<iframe  src="https://test.aestar.com.ua/~sergey/streetview/v6/?lat=${this.latitude}&lng=${this.longitude}" frameborder="0"></iframe>`
    }
    

    
}
