import { ConvaiClient } from "convai-web-sdk";

export class ConvaiChatApp {
  private apiKey: string;
  private characterId: string;
  private userInput: string | null;
  private convaiClient: ConvaiClient | null;
  private userText: string[];
  private npcText: string[];
  private updateChat: (userText: string[], npcText: string[]) => void;

  constructor(
    apiKey: string,
    characterId: string,
    updateChatCallback: (userText: string[], npcText: string[]) => void
  ) {
    this.apiKey = apiKey;
    this.characterId = characterId;

    this.convaiClient = null;
    this.userText = [];
    this.npcText = [];
    this.updateChat = updateChatCallback;

    this.initializeConvaiClient();
  }

  private initializeConvaiClient(): void {
    this.convaiClient = new ConvaiClient({
      narrativeTemplateKeysMap: undefined, sessionId: "", speaker: "", speakerId: "",
      apiKey: this.apiKey,
      characterId: this.characterId,
      enableAudio: false,
      disableAudioGeneration: true
    });

    this.convaiClient.setResponseCallback((response: any) => {
      if (response.hasAudioResponse()) {
        const audioResponse = response.getAudioResponse();
        this.npcText.push(audioResponse.getTextData());
        if (this.userInput) {
          this.userText.push(this.userInput);
        }
        if (this.userInput) this.userInput = '';
        this.updateChat(this.userText, this.npcText);
      }
    });
  }

  public sendText(text): void {
    if (text && text.trim()) {
      this.userInput = text;
      this.convaiClient?.sendTextChunk(text);
    }
  }
}
