import './styles/index.css'
import './styles/font.css'
import * as BABYLON from 'babylonjs';
import Scene from './Scene'
import Loader from './Loader_';
import AudioController from './AudioController';


window.addEventListener('DOMContentLoaded', () => {
    // const loader = new Loader('preloader');
    let isProgress = true
    const audioController = new AudioController()
    const loader = new Loader({
        dom: document.getElementById("preloader")
    });
    
    
    const scene = new Scene('renderCanvas');
    const loadScene = async() => {
        try {
            await scene.init();
            isProgress = false
            loader.loadedComplete(scene, audioController)
            console.log("All components are loaded, ready to start!");
        } catch (error) {
            console.error("Initialization failed:", error);
        }
    }
    const progressBar = () => {
        if(!isProgress) return
        let progress = document.getElementById("progress");
        let width = 1;
        let id = setInterval(frame, 30);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
            progressBar();
          }
          else {
            width++;
            progress.style.width = width + '%';
          }
        }
    }
    loadScene()
    progressBar()
});
