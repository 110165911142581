import * as BABYLON from 'babylonjs';
import EarthMarker from "./EarthMarker"
import gsap from "gsap"
import Gallery from "./Gallery"
import Chat from "./Chat"
import Earth from "./Earth"
import { isMobile } from './utils';
import { locationsInfo } from './locationInfo';

type Mode = 'globe' | 'gallery' | 'full'

export default class EarthMarkerController{
    private mode: Mode = 'globe'
    private activeMarker: number
    private markers: EarthMarker[]
    private currentMarkerMesh: BABYLON.AbstractMesh
    private earth: Earth
    private scene: BABYLON.Scene
    private camera: BABYLON.FreeCamera
    private gallery: Gallery
    private chat: Chat
    private markerChangingPosition: boolean = false
    private markerScaleGallery: number = isMobile() ? 5 : 10
    private touchStartX = 0;
    private touchEndX = 0;
    private isSwipe: boolean = false
    private currentLocationInfo: number = 0
    private locationInfoItem: any

    constructor(
        earth: Earth,
        scene: BABYLON.Scene,
        camera: BABYLON.FreeCamera,
        gallery: Gallery,
        chat: Chat
    ){
        this.earth = earth
        this.scene = scene
        this.camera = camera
        this.gallery = gallery
        this.chat = chat
        this.init()
    }

    private init(){
        const backButton = document.getElementById('backButton')

        backButton.addEventListener('click', () => this.handleBackButton())

        this.controlButtonsHandler()
        if(isMobile()){
            this.addSwipeListeners()
        }

        this.scene.registerBeforeRender(() => {
            if (this.mode === 'gallery') {
                this.markers.forEach((marker) => {
                    const markerMesh = marker.getMarkerMesh()
                    markerMesh.rotation.y += 0.001;
                })

            }
        });
    }

    public setMarkers(markers: EarthMarker[]){
        this.markers = markers
    }

    public setActiveMarker(value: number){
        this.activeMarker = value
        this.currentMarkerMesh = this.markers[value].getMarkerMesh()
    }

    public getMode(){
        return this.mode
    }

    private cameraRotation(value: number){
        if(value < 0)this.changeLocationInfo('prev')
        if(value > 0)this.changeLocationInfo('next')
        const camera = this.scene.activeCamera as BABYLON.FreeCamera;
        const targetRotation = camera.rotation.y + BABYLON.Tools.ToRadians(value);
        const animation = new BABYLON.Animation(
            "cameraRotationAnimation",
            "rotation.y",
            30,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        const keys = [];
        keys.push({
            frame: 0,
            value: camera.rotation.y
        });
        keys.push({
            frame: 30,
            value: targetRotation
        });
        animation.setKeys(keys);

        camera.animations = [];
        camera.animations.push(animation);
        this.scene.beginAnimation(camera, 0, 30, false);
    }

    private controlButtonsHandler(){

        const btnLeft = document.getElementById('controlLeftBtn')
        const btnRight = document.getElementById('controlRightBtn')
        const prev = document.getElementById('link-prev')
        const next = document.getElementById('link-next')
        btnLeft.addEventListener('click', () => {
            if(this.mode === 'full') this.cameraRotation(-360 / 7)
            else if(this.mode === 'gallery') this.galleryMove('left')
        })
        btnRight.addEventListener('click', () => {
            if(this.mode === 'full') this.cameraRotation(360 / 7)
            else if(this.mode === 'gallery') this.galleryMove('right')
        })
        prev.addEventListener('click', () => {
            this.changeLocation('left')
        })
        next.addEventListener('click', () => {
            this.changeLocation('right')
        })
    }

    private handleBackButton(){

        if(this.activeMarker !== null){
            if(this.mode === 'gallery'){
                this.changeMode('globe')
                this.exitGalleryMode()
            } else if(this.mode === 'full'){
                this.changeMode('gallery')
                this.exitFullMode()
            }
        }
    }

    public displayControlButtons(show: boolean){
        const controlButtons = document.getElementById('controlButtons')
        if(show) {
            controlButtons.classList.replace('hide', 'show')
        } else {
            controlButtons.classList.replace('show', 'hide')
        }
    }

    private changeMode(mode: Mode){
        this.mode = mode
    }

    public resetMarkers(): void {

        this.earth.mesh.rotationQuaternion = BABYLON.Quaternion.RotationAxis(BABYLON.Axis.X, -Math.PI);
        const frameContainer = document.getElementById('frameContainer');
        const camera = this.scene.activeCamera as BABYLON.FreeCamera;

        if (this.markers[this.activeMarker].toFrame) {
            frameContainer.style.display = 'none';
            frameContainer.innerHTML = '';
        }
        this.markers.forEach((marker) => {
            const markerMesh = marker.getMarkerMesh()
            markerMesh.scaling = new BABYLON.Vector3(1,1,1)
            markerMesh.position = BABYLON.Vector3.Zero()
            markerMesh.material.alpha = 1
            markerMesh.visibility = 0

        })

        gsap.to(this.earth.rootTransformNode.scaling, 1, {x:2,y:2,z:2, onComplete: () => {
            this.activeMarker = null
            this.markers.forEach((marker) => {
                marker.resetMarker()
            })
            this.earth.firstAnimationToLocation(0)
        }})

    }

    public selectMarkerGlobal(mode: Mode): void {
        this.isSwipe = false
        this.changeMode(mode)
        const blurEl = document.getElementById('blur')
        const frameContainer = document.getElementById('frameContainer')
        if(this.markers[this.activeMarker].toFrame){
            blurEl.style.display = 'block'
            blurEl.classList.add('fadeIn')
        }
        const camera = this.scene.activeCamera as BABYLON.FreeCamera;
        const globalPosition = this.currentMarkerMesh.getAbsolutePosition();
        this.currentMarkerMesh.parent = null
        this.currentMarkerMesh.position = globalPosition
        // gsap.to(this.earth.mesh.material, {direction: 0.3, alpha: 0})

        // gsap.to(this.earth.rootTransformNode.scaling, .3, {x: 0, y: 0, z: 0})
        // gsap.to(this.currentMarkerMesh.scaling, 1, {x: this.markerScaleGallery, y: this.markerScaleGallery, z: this.markerScaleGallery, delay: 0.3})
        const cameraPosition = camera.position.clone()
        gsap.to(camera.position, 2, {x: this.currentMarkerMesh.position.x, y: this.currentMarkerMesh.position.y, z: this.currentMarkerMesh.position.z - 1, onComplete: () => {
            this.earth.rootTransformNode.scaling = BABYLON.Vector3.Zero()
            camera.position = cameraPosition
            this.currentMarkerMesh.scaling = new BABYLON.Vector3(this.markerScaleGallery,this.markerScaleGallery,this.markerScaleGallery)
            this.currentMarkerMesh.position = new BABYLON.Vector3(camera.position.x,camera.position.y,camera.position.z + 1)
            this.earth.bloomEnabled(false)
            document.getElementById('backButton').classList.replace('hide', 'show-f')
            if(this.markers[this.activeMarker].toFrame){

                frameContainer.style.display = 'block'
                frameContainer.innerHTML = this.markers[this.activeMarker].createFrame()
                setTimeout(() => {
                    blurEl.classList.remove('fadeIn')
                    blurEl.style.opacity = '1'
                    blurEl.classList.add('fadeOut')
                    setTimeout(() => {
                        blurEl.classList.remove('fadeOut')
                        blurEl.style.opacity = '0'
                        blurEl.style.display = 'none'
                    }, 2000);

                }, 2000);
            } else {
                this.gallery.openGallery()
                this.markers.forEach((marker, id) => {
                    this.createGalleryMenu(marker, id)
                })
                this.updateLocationName()
                this.changeGalleryTitle()
                this.isSwipe = true
                this.displayControlButtons(true)
            }
        }})
    }

    public selectMarkerGallery(mode: Mode){
        this.isSwipe = false
        this.changeMode(mode)
        const camera = this.scene.activeCamera as BABYLON.FreeCamera;
        this.gallery.closeGallery()
        this.markers.forEach((marker) => {
            const markerMesh = marker.getMarkerMesh()
            if(this.currentMarkerMesh !== markerMesh){
              markerMesh.material.alpha = 0
              markerMesh.scaling = new BABYLON.Vector3(1000,1000,1000)
              markerMesh.position = new BABYLON.Vector3(camera.position.x,camera.position.y + 5,camera.position.z)
            }
        })
        gsap.to(this.currentMarkerMesh.scaling, 1, {x: 1000, y: 1000, z: 1000})
        gsap.to(this.currentMarkerMesh.position, 1, {x: camera.position.x, y: camera.position.y + 5, z: camera.position.z, onComplete: () => {
            this.earth.disposeParticles()
            this.createLocationInfo(this.activeMarker)
            this.chat.showUI()
            document.getElementById('navigateButton').classList.replace('hide', 'show-f')
            document.getElementById('locationInfo').classList.replace('hide', 'show')
            this.isSwipe = true
        }})
    }

    private exitFullMode(){
        this.isSwipe = false
        document.getElementById('navigateButton').classList.replace('show-f', 'hide')
        document.getElementById('locationInfo').classList.replace('show', 'hide')
        const camera = this.scene.activeCamera as BABYLON.FreeCamera;

        this.chat.destroy()
        this.earth.createParticles()


        this.camera.setTarget(BABYLON.Vector3.Zero())

        gsap.to(this.currentMarkerMesh.scaling, {duration: 1, x: this.markerScaleGallery, y: this.markerScaleGallery, z: this.markerScaleGallery})
        gsap.to(this.currentMarkerMesh.position, {duration: 1.1, x: camera.position.x, y: camera.position.y, z: camera.position.z + 1, onComplete: () => {

            this.markers.forEach((marker, id) => {
                this.createGalleryMenu(marker, id)
                const markerMesh = marker.getMarkerMesh()
                if(this.activeMarker === id) markerMesh.material.alpha = 1
                markerMesh.visibility = 1
            })
            this.gallery.openGallery()
            this.updateLocationName()
            this.isSwipe = true
        }})
    }

    private exitGalleryMode(){
        this.isSwipe = false
        this.displayControlButtons(false)
        this.resetMarkers()
        document.getElementById('backButton').classList.replace('show-f', 'hide');
        this.gallery.closeGallery()
    }

    updateLocationName() {
        const citiesNames = document.querySelectorAll('#gallery-screen .cities p') as NodeListOf<HTMLElement>

        this.markers.forEach((marker, index) => {
            const markerMesh = marker.getMarkerMesh()
            let delta = this.activeMarker - index
            if (delta >=  this.markers.length / 2) {
                delta = delta - this.markers.length
            }
            if (delta <= -this.markers.length / 2) {
                delta = delta + this.markers.length
            }
            // names
            citiesNames[index].style.opacity = delta === 0 ? '1' : '0'
            citiesNames[index].style.left = `${50 + delta * 150}%`

        })
    }

    createGalleryMenu(marker: EarthMarker, index: number) {
        marker.enabledTexture(true)
        const camera = this.scene.activeCamera as BABYLON.FreeCamera;
        const markerMesh = marker.getMarkerMesh()
        const globalPosition = markerMesh.getAbsolutePosition();
        markerMesh.parent = null
        markerMesh.position = globalPosition
        markerMesh.scaling = new BABYLON.Vector3(this.markerScaleGallery,this.markerScaleGallery,this.markerScaleGallery)
        markerMesh.position = new BABYLON.Vector3(camera.position.x, camera.position.y, camera.position.z + 1);
        markerMesh.visibility = 1
        if(this.activeMarker !== index) markerMesh.material.alpha = 0
    }

    moveLeft(param: number, paramsList: any){
        if (param === 0) {
            param = paramsList.length - 1
        } else {
            param--
        }
        return param
    }
    moveRight(param: number, paramsList: any){
        if (param === paramsList.length - 1) {
            param = 0
        } else {
            param++
        }
        return param

    }

    public changeLocation(value: 'left' | 'right' | number){
        if(this.markerChangingPosition) return
        this.markerChangingPosition = true
        if(value === 'left') this.activeMarker = this.moveLeft(this.activeMarker, this.markers)
        else if(value === 'right') this.activeMarker = this.moveRight(this.activeMarker, this.markers)
        else if(typeof value === 'number') this.activeMarker = value
        this.createLocationInfo(this.activeMarker)
        gsap.to(this.currentMarkerMesh.material, {duration: 0.5, alpha: 0, onComplete: () => {
            this.currentMarkerMesh.visibility = 0
            const nextMarker = this.markers[this.activeMarker].getMarkerMesh()
            nextMarker.visibility = 1
            gsap.to(nextMarker.material, {duration: 0.5, alpha: 1, onComplete: () => {
                this.markerChangingPosition = false
                this.currentMarkerMesh = nextMarker
            }

            } )
        }})

        // this.updateMarkerPosition()
    }

    changeGalleryTitle(){
        const galleryTitle = document.getElementById('gallery-title')
        const galleryTitleText = locationsInfo.find((item) => item.id === this.activeMarker).galleryTitle
        galleryTitle.innerHTML = galleryTitleText ? galleryTitleText : ''
    }


    public galleryMove(value: 'left' | 'right'){
        if (this.markerChangingPosition) return

        if(value === 'left') this.activeMarker = this.moveLeft(this.activeMarker, this.markers)
        else if(value === 'right') this.activeMarker = this.moveRight(this.activeMarker, this.markers)
        this.changeGalleryTitle()
        const nextMarker = this.markers[this.activeMarker].getMarkerMesh()
        gsap.to(this.currentMarkerMesh.rotation, {duration: 1, y: this.currentMarkerMesh.rotation.y + Math.PI * 2})
        gsap.to(this.currentMarkerMesh.material, {duration: 1, alpha: 0})
        gsap.to(nextMarker.rotation, {duration: 1, y: nextMarker.rotation.y + Math.PI * 2})
        gsap.to(nextMarker.material, {duration: 1, alpha: 1})

        this.currentMarkerMesh = nextMarker
        this.updateLocationName()
    }

    addSwipeListeners() {
        const canvas = document.getElementById('renderCanvas')

        canvas.addEventListener('touchstart', (event) => {
            if(this.isSwipe) {
                this.touchStartX = event.changedTouches[0].screenX;
            }
        });

        canvas.addEventListener('touchmove', (event) => {
            if(this.isSwipe) {
                this.touchEndX = event.changedTouches[0].screenX;
            }
        });

        canvas.addEventListener('touchend', () => {
            if(this.isSwipe) {
                this.handleSwipe();
            }
        });
    }

    handleSwipe() {
        const swipeThreshold = 50;
        if (this.touchEndX < this.touchStartX - swipeThreshold) {
            if(this.mode === 'gallery') this.galleryMove('left');
            else if(this.mode === 'full') this.cameraRotation(360 / 7)
        }
        if (this.touchEndX > this.touchStartX + swipeThreshold) {
            if(this.mode === 'gallery') this.galleryMove('right');
            else if(this.mode === 'full') this.cameraRotation(-360 / 7)
        }
    }

    createLocationInfo(id: number){
        const title = document.getElementById('locationTitle')
        const description = document.getElementById('locationDescription')
        title.innerHTML = ''
        description.innerHTML = ''
        this.locationInfoItem = locationsInfo.find((item) => item.id === id)
        if(!this.locationInfoItem) return
        title.innerHTML = this.locationInfoItem.title
        description.innerHTML = this.locationInfoItem.descriptions[0]
    }
    private moveLocationDescription(dir: -1 | 1){
        const description = document.getElementById('locationDescription')
        gsap.to(description, {duration: 0.5, x: -1000 * dir, onComplete: () => {
            this.currentLocationInfo = this.moveLeft(this.currentLocationInfo, this.locationInfoItem.descriptions)
            description.innerHTML = this.locationInfoItem.descriptions[this.currentLocationInfo]
            gsap.fromTo(description, {x: 1000 * dir}, {x: 0, duration: 0.5})
        }})
    }
    changeLocationInfo(value: 'prev' | 'next'){

        if(!this.locationInfoItem) return
        if(value === 'prev'){
            this.moveLocationDescription(-1)
        }
        if(value === 'next'){
            this.moveLocationDescription(1)
        }

    }

    public goToLocationFromChat(location: string) {
        console.log('Chat location: ', location)
        const locationId = locationsInfo.find((loc) => loc.title === location).id
        this.changeLocation(locationId)
    }
}
