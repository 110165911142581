import './styles/chat.css'
import {ConvaiChatApp} from "./ConvaiChatApp";
import EarthMarkerController from "./EarthMarkerController";


export default class Chat {
    public icon: HTMLElement
    public chatBox: HTMLElement
    public titleBox: HTMLElement
    public sendButton: HTMLElement
    public messageWrapper: HTMLElement
    public messages: HTMLElement
    private textarea: HTMLTextAreaElement
    private isLoading: boolean = false
    private convaiChatApp: any
    private earthMarkerController: any

    constructor(){
        this.init()
    }

    destroy() {
        this.icon.classList.add('default-hide')
        this.icon.classList.remove('smooth-show')

        this.chatBox.classList.add('default-hide')
        this.chatBox.classList.remove('smooth-show')


        this.messages.innerHTML = ''
        const textarea = document.querySelector('#chat-box .message-input') as HTMLTextAreaElement;
        if (textarea && textarea.value) {
            textarea.value = ''
        }
    }

    init() {
        this.icon = document.querySelector('#chat-button')
        this.chatBox = document.querySelector('#chat-box')
        this.titleBox = document.querySelector('#chat-box .chat-title')
        this.sendButton = document.querySelector('#chat-box .message-submit')
        this.messages = document.querySelector('#chat-box .messages')
        this.messageWrapper = document.querySelector('#chat-box .messages-wrapper')
        this.textarea = document.querySelector('#chat-box .message-input') as HTMLTextAreaElement

        this.icon.addEventListener('click', () => {
            this.icon.classList.remove('smooth-show')
            this.chatBox.classList.add('smooth-show')

            const firstMessage = document.querySelector('#chat-box .message') as HTMLElement
            if (!firstMessage) {
                this.convaiChatApp.sendText('こんにちは!')
                this.updateMessageBox(false)
            }
        })
        this.titleBox.addEventListener('click', () => {
            this.icon.classList.add('smooth-show')
            this.chatBox.classList.remove('smooth-show')
        })
        this.sendButton.addEventListener('click', () => {
            if (this.textarea.value && !this.isLoading) {
                this.updateMessageBox(true)
            }
        })
        this.textarea.addEventListener('keydown', (e) => {
            if (e.key === "Enter") {
                if (this.textarea.value && !this.isLoading) {
                    this.updateMessageBox(true)
                }
            }
        });

       this.convaiChatApp = new ConvaiChatApp(
            '643267ee722ac34b227c3cf2a28317aa',
            '3a910be8-6e86-11ef-a5d4-42010a7be011',
            this.applyBotAnswer.bind(this)
        );
    }

    public setEarthMarkerController(controller: EarthMarkerController) {
        this.earthMarkerController = controller
    }

    showUI(){
        this.icon.classList.remove('default-hide')
        this.chatBox.classList.remove('default-hide')
        this.icon.classList.add('smooth-show')
    }

    handleUserText() {
        const textarea = document.querySelector('#chat-box .message-input') as HTMLTextAreaElement;
        const text = textarea.value
        this.convaiChatApp.sendText(text)
        textarea.value = ''
        this.checkSpecialLocations(text)
        return text
    }

    checkSpecialLocations(userText: string) {
        const possibleLocation = [
            '円覚寺',
            '英勝寺'
        ]
        let selectedLocation = ''

        possibleLocation.forEach((location) => {
          if (userText.includes(location)) {
            selectedLocation = location
          }
        })

        if (location) {
            this.earthMarkerController.goToLocationFromChat(selectedLocation)
        }
    }

    updateMessageBox(useUserAnswer = false){
        let currentMessages = this.messages.innerHTML

        if (useUserAnswer) {
            const userText = this.handleUserText()
            const userAnswer = `<div class="message user-message">
                          <div class="content"><p>${userText}</p></div>
                       </div>`
            currentMessages += userAnswer
        }

        const botAnswer = `<div class="message bot-message loading"><div class="image-wrapper">
                                <img src="./public/icons/person2.png" alt="bot-icon" />
                            </div>
                            <div class="content">
                                <div class="dots"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div>
                                <p></p>
                            </div>
                        </div>`
        currentMessages += botAnswer


        this.messages.innerHTML = currentMessages
        this.isLoading = true

        this.messageWrapper.scrollTo({
            top: this.messages.scrollHeight,
            behavior: 'smooth'
        });
    }

    applyBotAnswer(userText, npcText) {
        console.log('userText', userText)
        console.log('npcText', npcText)

        const loadingElements = document.querySelectorAll('.bot-message.loading')
        loadingElements.forEach((element) => {
            element.classList.remove('loading')
        })
        this.isLoading = false

        const lastBotAnswer = this.messages.querySelector('.bot-message:last-child .content p')
        if (lastBotAnswer) {
            lastBotAnswer.innerHTML = npcText[npcText.length - 1]
        }
        this.messageWrapper.scrollTo({
            top: this.messages.scrollHeight,
            behavior: 'smooth'
        });
    }
}
