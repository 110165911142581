import * as BABYLON from 'babylonjs'
import { createEnv } from './utils';
import Earth from './Earth';

class Scene {
    private _canvas: HTMLCanvasElement;
    private _engine: BABYLON.Engine;
    private _scene: BABYLON.Scene;
    private light: BABYLON.Light
    private _camera: BABYLON.FreeCamera;
    private controlButtonLeft: HTMLElement
    private controlButtonRight: HTMLElement
    public earth: Earth
    constructor(canvasElement: string) {
        // Create canvas and engine.
        this._canvas = document.getElementById(canvasElement) as HTMLCanvasElement;
        this._engine = new BABYLON.Engine(this._canvas, true);
    }

    async init(): Promise<void> {
        this._scene = new BABYLON.Scene(this._engine);
        this._scene.clearColor = new BABYLON.Color4(0, 0, 0, 1);
        this._camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 0, -10), this._scene);

        this.light = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(1, -0.5, -1), this._scene);
        this.light.intensity = 2;

        const ambientLight = new BABYLON.HemisphericLight("ambientLight", new BABYLON.Vector3(0, 1, 10), this._scene);
        ambientLight.intensity = 1;
        const pipeline = new BABYLON.DefaultRenderingPipeline(
            "pipeline",
            true,
            this._scene,
            [this._scene.activeCamera]
        );
        
        pipeline.bloomEnabled = true;
        pipeline.bloomThreshold = 0.1;
        pipeline.bloomWeight = 0.8;
        pipeline.bloomKernel = 64;
        pipeline.bloomScale = 0.5;


        this._camera.setTarget(BABYLON.Vector3.Zero());
       
        try {
            this.earth = new Earth(this._scene, this._camera, pipeline);
            await this.earth.load();
            console.log("Earth model loaded and positioned!");
        } catch (error) {
            console.error("Error loading Earth model:", error);
            throw error;
        }

        this.doRender();
        return Promise.resolve();
    }

   

    private doRender(): void {
        // Run the render loop.
        this._engine.runRenderLoop(() => {
            this._scene.render();
        });

        // The canvas/window resize event handler.
        window.addEventListener('resize', () => {
            this._engine.resize();
        });
    }
}

export default Scene