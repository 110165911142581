export const locationsInfo = [
    {
        id: 0,
        galleryTitle: '鎌倉総合病院へようこそ',
        title: "湘南鎌倉総合病院",
        descriptions: [
            "湘南鎌倉総合病院は、最先端の医療機器と高度な治療技術で、24 時間体制の救急医療を提供しています。",
            "MRI やCT などの高度検査は、事前予約により迅速かつ的確な診断 が可能です。",
            "患者様とご家族の安心を第一に、広々とした待合室と最新設備が完 備されています。",
            "最新鋭の医療機器を駆使し、専門的な治療を提供する診療科が豊富 に揃っています。",
            "感染症対策を徹底し、すべての来院者にはマスクの着用をお願いし ております。",
            "ご家族やお見舞いの方のための待機スペースは、快適な環境を整え た2   階にご用意しています。",
            "アクセスも便利で、専用駐車場は病院裏手に完備されています。"
        ]
    },
    {
        id: 1,
        galleryTitle: '英勝寺',
        title: "英勝寺",
        descriptions: [
            "英勝寺は、江戸時代初期に創建された、鎌倉唯一の尼寺です。",
            "境内には、美しい庭園と歴史的な建物があり、四季折々の風景を楽しむことができます。",
            "英勝寺の本堂には、歴史的な仏像が安置されており、静かな祈りの空間を提供しています。",
            "寺の入口にある山門は、江戸時代に建てられたもので、鎌倉の歴史を感じることができます。",
            "徳川家との深い縁があり、英勝寺は徳川家康のゆかりの地としても知られています。",
            "鎌倉駅から徒歩15分の距離にあり、鎌倉観光の際に訪れやすいスポットです。",
            "英勝寺は、静かな雰囲気の中で心を落ち着けるのに最適な場所です。"
        ]
    },
    {
        id: 2,
        galleryTitle: '円覚寺',
        title: "円覚寺",
        descriptions: [
            "円覚寺は、鎌倉五山の一つに数えられる、鎌倉時代に創建された歴史的な禅寺です。",
            "境内には広大な敷地が広がり、美しい自然と禅の静けさを楽しむことができます。",
            "円覚寺の象徴的な山門は、力強い禅の精神を感じさせる壮大な建築です。",
            "本堂には、禅の修行道場があり、修行僧が日々修行を積んでいます。",
            "円覚寺には、歴史的な建造物や仏像が多数あり、鎌倉時代の文化に触れることができます。",
            "秋の紅葉や春の桜の時期には、円覚寺は美しい自然が楽しめるスポットです。",
            "円覚寺は、鎌倉駅から徒歩10分でアクセスしやすく、鎌倉観光の定番スポットです。"
        ]
    }
]
