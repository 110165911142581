// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:300);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MPlus rounded 1c */
.m-plus-rounded-1c-thin {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .m-plus-rounded-1c-light {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .m-plus-rounded-1c-regular {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .m-plus-rounded-1c-medium {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .m-plus-rounded-1c-bold {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .m-plus-rounded-1c-extrabold {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .m-plus-rounded-1c-black {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  /* Poppins */
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAEA,qBAAqB;AACrB;IACI,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA,YAAY;EACZ;IACE,kCAAkC;IAClC,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Raleway:300');\n\n/* MPlus rounded 1c */\n.m-plus-rounded-1c-thin {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 100;\n    font-style: normal;\n  }\n  \n  .m-plus-rounded-1c-light {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 300;\n    font-style: normal;\n  }\n  \n  .m-plus-rounded-1c-regular {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n  }\n  \n  .m-plus-rounded-1c-medium {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 500;\n    font-style: normal;\n  }\n  \n  .m-plus-rounded-1c-bold {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 700;\n    font-style: normal;\n  }\n  \n  .m-plus-rounded-1c-extrabold {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 800;\n    font-style: normal;\n  }\n  \n  .m-plus-rounded-1c-black {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 900;\n    font-style: normal;\n  }\n\n  /* Poppins */\n  .poppins-thin {\n    font-family: \"Poppins\", sans-serif;\n    font-weight: 100;\n    font-style: normal;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
